@import '../../variables/variables.scss';

.header {
    max-width: 98%;
    margin: auto;
    height: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    gap: 20px;
    &__logoContainer {
        width: 300.23px;
        height: 193.99px;
        background: #ffffff;
        border-radius: 10px;
        &--logo {
            max-width: 300.23px;
            height: 100%;
            border-radius: 10px;
        }
    }
    &__nameContainer {
        width: 100%;
        min-width: 275px;
        height: 164px;
        border-radius: 10px;
        background-color: $backgroundColor-elements;
        background: hsla(58, 100%, 99%, 0.75);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px;
    }
}

.navContainer {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ulContainer {
    width: 100%;
    height: auto;
    margin: 0px;
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 0px;
    gap: 10px;
}

.nameContainer {
    height: 70%;
    width: 100%;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    &__title {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        font-family: $font-family-comfortaa;
        font-size: 28px;
        margin: 0px;
    }
    &__text1 {
        font-family: $font-family-red-hat-display;
        margin: 0px;
    }
    &__text2 {
        font-family: $font-family-red-hat-display;
        margin: 0px;
    }
}

.contact {
    width: 100%;
    min-width: 301.77px;
    height: 194px;
    perspective: 10000px;
    &__inner {
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        transition: transform 1s;
    }
  }

  .contact:hover .contact__inner {
    transform: rotateY(180deg);
  }

  .frontContact {
    position: absolute;
    width: 100%;
    height: 164px;
    padding: 15px 0px;
    backface-visibility: hidden;
    background: hsla(58, 100%, 99%, 0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    border-radius: 10px;
    transform: rotateY(0deg);
    &__text {
        display: flex;
        flex-direction: column;
        font-family: $font-family-red-hat-display;
        margin: 0px;
    }
    &__title {
        margin: 0px;
    }
  }

  .backContact {
    position: absolute;
    width: 100%;
    height: 164px;
    padding: 15px 0px;
    backface-visibility: hidden;
    background: hsla(58, 100%, 99%, 0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    border-radius: 10px;
    transform: rotateY(180deg);
    &__contact {
        height: 80%;
        display: flex;
        flex-direction: column;
        &--container {
            height: 50%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 3%;
            text-decoration: none;
            font-family: $font-family-red-hat-display;
            font-size: 12px;
        }
    }
  }

  .backContactImg {
    width: 20px;
    height: 25px;
  }

  .backContactText {
    font-family: $font-family-red-hat-display;
    margin: 0px;
  }

  .contact__inner--front h3, .contact__inner--front p  {
    margin: 0px 20px;
  }
  
  .contact__inner--back h3, .contact__inner--back h4, .contact__inner--back p {
    margin: 0px 20px;
  }

/******* Media queries **********/
/*  Small devices (tablets and desktop, less than 1204.1px)  */
@media (min-width: 640.1px) and (max-width: 1338px){
    .header {
        display: grid;
        grid-template-columns: 324.36px auto 301.77px;
        grid-template-rows: 194px 194px;
        &__logoContainer {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        &__nameContainer {
            width: auto;
            grid-column-start: 2;
            grid-column-end: 4;
        }
    }
    .contact {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

/*  Small devices (tablets, less than 978px)  */
@media (max-width: 977px) {
    .header {
        margin-top: 20px;
        padding-bottom: 20px;
        width: 98%;
        height: 405px;
    }
}

/* Small devices (mobiles, less than 690.1px)  */
@media (max-width: 690px) {
    .header {
        height: auto;
        display: flex;
        flex-direction: column;
        font-size: 0.8em;
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0px;
        &__logoContainer {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        &__nameContainer {
            padding: 0px;
            height: 194px;
        }
    }

    .nameContainer {
        padding: 0px;
    }

    .frontContact {
        padding: 0px;
        height: 194px;
    }

    .backContact {
        padding: 0px;
        height: 194px;
    }
}