@import '../../variables/variables.scss';

.mesRealisationsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: hsla(58, 100%, 99%, 0.75);
    width: 98%;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    padding-bottom: 20px;
}

.mesServicesContainer {
    width: 98%;
    height: auto;
    margin: auto;
    margin-top: 20px;
    background: rgba(255, 255, 250, 0.75);
    border-radius: 10px;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    &__title {
        margin: 0px;
    }
}

/******* Media queries **********/
/* Small devices (mobiles, less than 641px)  */
@media (max-width: 640px) {
    .mesRealisationsContainer {
        margin-bottom: 10px;
    }
    .mesServicesContainer {
        margin-top: 0px;
    }
}