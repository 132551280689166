@import '../../variables/variables.scss';

.aboutMeSection {
    width: 98%;
    height: 100%;
    margin: auto;
    padding: 20px 0px;
    background-color: $backgroundColor-elements, ;
    border-radius: 10px;
    background: hsla(58, 100%, 99%, 0.75);
    &__title {
        margin-top: 0px;
    }
}

.aboutMeContainer {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    gap: 3%;
    margin: 0px 16px;
    &__img {
        max-width: 320px;
        height: 320px;
        animation: slideFromLeft 1s ease-in-out forwards;
    }
    &__text {
        max-width: 320px;
        height: auto;
        font-family: $font-family-red-hat-display;
        font-size: 0.9em;
        font-weight: 600;
        text-align: center;
        animation: slideFromRight 1s ease-in-out forwards;
    }
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.maStackSection {
    width: 98%;
    height: auto;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    margin: auto;
}

.maStackContainer, .mesOutilsContainer {
    width: 100%;
    border-radius: 10px;
    padding: 15px 0px;
    background-color: $backgroundColor-elements;
    background: hsla(58, 100%, 99%, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 20px;
    &__title {
        margin: 0px 0px 20px 0px;
        padding: 0px 16px;
    }
}

.maStack, .mesOutils {
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3%;
}

.stackCard {
    margin-bottom: 20px;
}

/******* Media queries **********/
/*  Large devices (Desktop, more than 2690px)  */
@media (min-width: 2600px) {
    .maStack, .mesOutils {
        display: grid;
        grid-template-columns: 221px 221px 221px 221px 221px;
        grid-template-rows: 86.14px 86.14px;
        height: auto;
        width: 100%;
        gap: 20px;
        padding-bottom: 2%;
    }
}


/*  Small devices (tablets, less than 999px)  */
@media (min-width: 475px) and (max-width: 998px) {
    .maStackContainer, .mesOutilsContainer {
        &__title {
            font-size: 16px;
            padding: 15px 15px;
        }
    }

    .maStack, .mesOutils {
        display: grid;
        grid-template-columns: 221px;
        grid-template-rows: 86.14px 86.14px 86.14px 86.14px 86.14px 86.14px;
        height: auto;
        width: 100%;
        gap: 20px;
        padding-bottom: 8%;
    }
}

/* Small devices (mobile, less than 640.1px)  */
@media (max-width: 640px) {
    .aboutMeSection {
        margin-top: 10px;
    }

    .maStackContainer, .mesOutilsContainer {
        margin-top: 0px;
    }
}

/* Small devices (mobile, less than 640.1px)  */
 @media (max-width: 640px) {
    .aboutMeContainer {
        display: flex;
        flex-direction: column;
        &__img {
            margin: auto;
            height: 271px;
        }
        &__text {
            margin: auto;
        }
    }
    .maStackSection {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }

    .maStackContainer, .mesOutilsContainer {
        &__title {
            font-size: 16px;
        }
    }
 }

 /* Small devices (mobile, less than 501px)  */
 @media (max-width: 500px) {
    .maStackContainer, .mesOutilsContainer {
        &__title {
            padding: 15px 0px 0px 0px;
        }
    }

    .maStackSection {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }
 }
