@import '../../variables/variables.scss';

.stackCard {
    width: 200px;
    height: 65px;
    padding: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
    border: 1px solid #C850C0;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: $backgroundColor-ProjectCard;
    &__img {
        width: 50px;
        height: 50px;
    }
    &__title {
        font-family: $font-family-comfortaa;
    }
}