@import './variables/variables.scss';

html {
  scroll-behavior: smooth;
  height: auto;
}

body {
  margin: 0;
  background-image: $backgroundColor-html

}

h2 {
  font-family: $font-family-comfortaa;
  text-align: center;
}
