@import '../../variables/variables.scss';

.slide {
    border: 1px solid $border-slide;
    border-radius: 5px;
    width: 145px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: $backgroundColor-ProjectCard;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    transition: all 0.5s;
    &__img {
        width: 100%;
        height: 47%;
        object-fit: cover;
        border-radius: 4px;
        border: 1px solid $border-slide;
    }
    &__title {
        height: 8%;
        font-size: 0.8em;
        font-family: $font-family-comfortaa;
        margin: 0px;
        text-align: center;
    }
    &__text {
        height: 105px;
        font-size: 0.8em;
        font-family: $font-family-red-hat-display;
        text-align: center;
        margin: 0px;
    }
}

.containerSlideLien {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    gap: 20px;
    &__lien {
        display: flex;
        justify-content: center;
        align-items: center;
        &--icon {
            width: 30px;
            height: 30px;
        }
    }
}

/******* Media queries **********/
/*  Small devices (tablets, less than 880px)  */
@media (max-width: 879px) {
    .slide {
        width: 90px;
        height: 170px;
        padding: 5px;
        &__img {
            height: 53%;
        }
        &__title {
            font-size: 0.6em;
        }
        &__text {
            font-size: 6px;
            height: 22.5%;
        }
    }

    .containerSlideLien {
        &__lien {
            &--icon {
                width: 20px;
                height: 20px;
            }
        }
    }
}

/*  Small devices (mobile, less than 560px)  */
@media (max-width: 559px) {
    .slide {
        width: 210px;
        height: 380px;
        padding: 6px;
        &__img {
            height: 216.6px;
        }
        &__title {
            height: auto;
            font-size: 1em;
            padding: 10px 0px;
        }
        &__text {
            height: 100%;
            font-size: 0.9em;
        }
    }

    .containerSlideLien {
        &__lien {
            height: 40px;
            &--icon {
                width: 30px;
                height: 30px;
            }
        }
    }
}


