@import '../../variables/variables.scss';

.footer {
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__textContainer {
      font-family: $font-family-red-hat-display;
      font-weight: 300;
      text-align: center;
      margin: 20px 0px;
      color: $backgroundColor-elements;
    }
}

.iconsContainerFooter {
    display: flex;
    justify-content: center;
    gap: 20px;
    &__icon {
        width: 30px;
        height: 30px;
    }
}

.downloadCV {
    background-color: $backgroundColor-ProjectCard;
    background: hsla(58, 100%, 99%, 0.75);
    text-decoration: none;
    border-radius: 5px;
    padding: 10px;
    font-family: $font-family-red-hat-display;
    font-weight: 500;
    color: $textColorBlack;
}

.downloadCV:hover {
    background: $backgroundColor-ProjectCard;
    color: #4158D0;
}

/*  Small devices (mobiles, less than 641px)  */
@media (max-width: 640px) {
    .footer {
        padding: 10px 16px;
    }
}
