@import '../../variables/variables.scss';

button {
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-family: $font-family-red-hat-display;
    font-size: 1em;
    width: 100px;
    height: 25px;
    padding: 2px;
    font-weight: bold;
}

button:hover {
    text-decoration: underline;
}

.active {
    color: white;
    background-color: $backgroundColor-btnNavBarHover;
}

.disabled {
    color: $textColorBlack;
    background-color: $backgroundColor-btnNavBar;
}