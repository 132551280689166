@import '../../variables/variables.scss';

.arrow {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2px solid $border-slide;
   border-radius: 50%;
   cursor: pointer;
   width: 50px;
   height: 50px;
   padding: 13px;
   &__icons {
      width: 18px;
      height: 32px;
   }
}

/******* Media queries **********/
/*  Small devices (tablets, less than 880px)  */
@media (max-width: 879px) {
   .arrow {
      width: 20px;
      height: 20px;
      padding: 10px;
      &__icons {
         width: 8px;
         height: 13px;
      }
   }
}


