@import '../../variables/variables.scss';

.sliderContainer {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 290px 0px 240px 0px;
    position: relative;
    &__title {
        position: absolute;
        top: 0px;
        z-index: 1;
    }
    &__slider {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform-style: preserve-3d;
        perspective: 2000px;
        position: absolute;
    }
    &__btnContainer {
        width: 100%;
        max-width: 1880px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0px 20px;
    }
}

.DropdownSliderContainer {
    margin: auto;
}

/******* Media queries **********/
/*  Small devices (tablets, less than 880px)  */
@media (max-width: 879px) {
    .sliderContainer {
        padding: 170px 0px 110px 0px;
    }
 }

 /*  Small devices (mobile, less than 560px)  */
@media (max-width: 559px) {

    .sliderContainer {
        padding: 262px 0px 200px 0px;
        &__btnContainer {
            padding: 0px 16px;
        }
    }
 }
 