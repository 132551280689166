@import '../../variables/variables.scss';

.dropdownContainer {
    width: 94%;
    max-width: 800px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.dropdownTitleContainer {
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 3px 3px 0px 0px;
    cursor: pointer;
    background-color: $backgroundColor-Dropdown;
    position: relative;
    &__title {
        width: 80%;
        padding-left: 20px;
        font-size: 20px;
        font-weight: 900;
    }
    &__arrow {
        transform: rotate(180deg);
        transition: transform 0.8s ease-in-out;
        width: 40px;
        height: 20px;
        position: absolute;
        right: 2%;
    }
}

.dropdownTitleContainer__arrow.open {
    transform: rotate(0deg);
}

.dropdownTextContainer {
    max-height: 0px;
    transition: max-height 0.8s ease-in-out;
    font-family: $font-family-red-hat-display;
    font-size: 12px;
    background-color: $backgroundColor-elements;
    &__text {
        margin: 0px;
        padding: 10px;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
    }
    &__containerIcons {
        display: flex;
        justify-content: center;
        gap: 20px;
        padding: 10px;
        &--icons {
            width: 30px;
            height: 30px;
        }
    }
}

.dropdownTextContainer.open {
    max-height: 500px;
    transition: max-height 0.8s ease-in-out;
}

/*  Small devices (mobiles, less than 641px)  */
@media (max-width: 640px) {
    .sectionDropdown {
        margin-top: 10px;
    }

.dropdownTitleContainer {
    &__title {
        font-size: 15px;
        padding-left: 8px;
    }
}

    .dropdownTextContainer {
        &__text {
            font-size: 12px;
        }
    }
}